export const labels = {
  artist: {
    EN: 'Artist',
    IT: 'Artista',
  },
  artists: {
    EN: 'Artists',
    IT: 'Artisti',
  },
  category: {
    EN: 'Category',
    IT: 'Categoria',
  },
  categories: {
    EN: 'Categories',
    IT: 'Categorie',
  },
  nationality: {
    EN: 'Nationality',
    IT: 'Nazionalità',
  },
  location: {
    EN: 'Location',
    IT: 'Sede',
  },
  place: {
    EN: 'Place',
    IT: 'Luogo',
  },
  locations: {
    EN: 'Locations',
    IT: 'Sedi',
  },
  connect: {
    EN: 'Link',
    IT: 'Link esterno',
  },
  address: {
    EN: 'Address',
    IT: 'Indirizzo',
  },
  contacts: {
    EN: 'Contacts',
    IT: 'Contatti',
  },
  times: {
    EN: 'Times',
    IT: 'Orari',
  },
  title: {
    EN: 'Title',
    IT: 'Titolo',
  },
  info: {
    EN: 'Information',
    IT: 'Informazioni',
  },
  date: {
    EN: 'Date',
    IT: 'Data',
  },
  dates: {
    EN: 'Dates',
    IT: 'Date',
  },
  multimedia: {
    EN: 'Multimedia',
    IT: 'Multimediali',
  },
  artist: {
    EN: 'Artist',
    IT: 'Artista',
  },
  artists: {
    EN: 'Artists',
    IT: 'Artisti',
  },
  location: {
    EN: 'Location',
    IT: 'Sede',
  },
  locations: {
    EN: 'Locations',
    IT: 'Sedi',
  },
  journal: {
    EN: 'Journal',
    IT: 'Giornale',
  },
  homepage: {
    EN: 'Homepage',
    IT: 'Homepage',
  },
  close: {
    EN: 'Close',
    IT: 'Chiudi',
  },
  year: {
    EN: 'Year',
    IT: 'Anno',
  },
  city: {
    EN: 'City',
    IT: 'Città',
  },
  country: {
    EN: 'Country',
    IT: 'Nazione',
  },
  relatedArticles: {
    EN: 'Related News',
    IT: 'Notizie correlate',
  },
};

export const resImgs = {
  xs: {
    sizes: ['xs', 'md'],
    media: '(max-width: 639px)',
  },
  sm: {
    sizes: ['sm', 'lg'],
    media: '(max-width: 767px)',
  },
  md: {
    sizes: ['md', 'md2x'],
    media: '(max-width: 1023px)',
  },
  lg: {
    sizes: ['lg', 'lg2x'],
    media: '(min-width: 1024px)',
  },
  lg2x: {
    sizes: ['lg2x', 'lg3x'],
    media: '(min-width: 2048px)',
  },
  // lg: {
  //   sizes: ['lg', 'lg2x'],
  //   media: '(max-width: 2047px)',
  // },
  // lg2x: {
  //   sizes: ['lg2x', 'lg3x'],
  //   media: '(max-width: 3072px)',
  // },
};
