import Image from '@components/common/Image';
import React from 'react';
import SanitizedContent from '@components/common/SanitizedContent';
import clsx from 'clsx';
import styled from 'styled-components';

function CopyBlock({ title, copy, align, bodyType, columns, paddingBottom, featuredImage }) {
  const isArticle = bodyType === 'article';
  const isHome = bodyType === 'home';
  const containerClasses = clsx([
    'col-span-12',
    !isArticle && !columns && 'md:col-span-8 lg:col-span-6',
    isHome && 'text-base sm:text-md',
    !isArticle && columns && `md:col-span-${columns}`,
    paddingBottom && 'pb-24 md:pb-56',
  ]);
  const titleClasses = clsx(['mb-12', isArticle && 'font-sans tracking-sans', isHome && 'text-base']);

  return (
    <>
      <StyledCopyBlock className={containerClasses}>
        {isArticle ? (
          <div className="md:max-w-3/4 lg:max-w-1/2">
            {title && <h3 className={titleClasses}>{title}</h3>}
            {copy && <SanitizedContent copy={copy} />}
          </div>
        ) : (
          <>
            {title && <h3 className={titleClasses}>{title}</h3>}
            {copy && <SanitizedContent copy={copy} />}
          </>
        )}
      </StyledCopyBlock>
      {featuredImage && (
        <section className="col-span-12 md:col-span-4 md:col-start-9 lg:col-span-3 lg:col-start-10 text-right">
          <Image image={featuredImage} />
        </section>
      )}
    </>
  );
}

const StyledCopyBlock = styled.div`
  ul {
    margin-left: 24px;
    list-style-position: inside;
    list-style-type: disc;
  }
`;

export default CopyBlock;
