import Image from '@components/common/Image';
import React from 'react';

function Column({ images, isFirst, isFull }) {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const intervalTiming = isFirst ? 2000 : 3000;
  const interval = React.useRef(null);
  const threshold = [0.0];

  const intervalHandler = React.useCallback(
    () =>
      setInterval(() => {
        if (activeIndex === images.length - 1) return setActiveIndex(0);

        return setActiveIndex(activeIndex + 1);
      }, intervalTiming),
    [activeIndex, images, setActiveIndex],
  );

  React.useEffect(() => {
    if (images.length > 1 && process.env.NODE_ENV === 'production') {
      interval.current = intervalHandler();
    }

    return () => {
      clearInterval(interval.current);
    };
  }, [activeIndex, images, intervalHandler]);

  return (
    <ul className={`relative overflow-hidden${isFull ? ' col-span-12' : ' col-span-6'}`}>
      {images.map((image, i) => (
        <li
          key={i}
          className={`absolute top-0 left-0 w-full h-full${i === activeIndex ? ' z-9 opacity-100' : ' z-0 opacity-0'}`}
        >
          <Image image={image} layoutFill />
        </li>
      ))}
    </ul>
  );
}

export default Column;
