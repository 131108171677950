import { AnimatePresence, motion } from 'framer-motion';

import Footer from '@components/common/Footer';
import Header from '@components/common/Header';
import Navigation from '@components/common/Navigation';
import OpeningCarousel from '@components/OpeningCarousel/';
import PropTypes from 'prop-types';
import React from 'react';
import useGeneralContext from '@components/hoc/useGeneralContext';

const Main = ({ children, pageContext }) => {
  const { seo, isHome, language, translations, topSpacingNone } = pageContext;
  const { activeLang, setActiveLang } = useGeneralContext();

  React.useEffect(() => {
    if (language && language !== activeLang) {
      setActiveLang(language);
    }
  });

  return (
    <>
      <AnimatePresence exitBeforeEnter>
        {!activeLang && (
          <motion.div
            key="loader"
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              delay: 0.25,
              duration: 0.5,
            }}
            className="fixed top-0 left-0 w-screen h-screen bg-black z-20"
          />
        )}
      </AnimatePresence>
      <OpeningCarousel isHome={isHome} />
      <Navigation />
      <Header translations={translations} />
      <main className={`mx-12 max-w-full ${topSpacingNone ? 'pb-12' : ' py-12'}`}>{children}</main>
      <Footer translations={translations} />
    </>
  );
};

Main.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  pageContext: PropTypes.object.isRequired,
};

Main.defaultProps = {
  pageContext: {
    language: 'IT',
  },
};

export default Main;
