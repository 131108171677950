import parse, { domToReact } from 'html-react-parser';

import React from 'react';

const options = {
  replace: ({ attribs, name, children }) => {
    if (!attribs || !name) return;
    if (
      attribs.class === 'page' ||
      attribs.class === 'section' ||
      attribs.class === 'layoutArea' ||
      attribs.class === 'column' ||
      name === 'div' ||
      name === 'section'
    ) {
      return <>{domToReact(children, options)}</>;
    }
  },
};

function SanitizedContent({ copy }) {
  return <>{parse(copy, options)}</>;
}

export default SanitizedContent;
