import { graphql, useStaticQuery } from 'gatsby';

import Cookies from 'js-cookie';
import { Link } from 'gatsby';
import React from 'react';
import clsx from 'clsx';
import parse from 'html-react-parser';
import useGeneralContext from '@components/hoc/useGeneralContext';

export default function NoticeDrawer() {
  const {
    wp: {
      siteSettings: {
        ACFSiteSettings: { noticeDrawer },
      },
    },
  } = useStaticQuery(query);
  const [isVisible, setIsVisible] = React.useState(false);
  const { activeLang } = useGeneralContext();
  let activeMessage;
  let messages = null;

  const contClasses = clsx([
    'flex justify-between items-center absolute bottom-0 left-0 transform transition-transform duration-200 ease-out w-full bg-black text-white p-12 cursor-pointer overflow-hidden',
    isVisible ? 'translate-y-full pointer-events-all' : 'translate-y-0 pointer-events-none',
  ]);

  if (activeLang) {
    activeMessage = noticeDrawer[`noticeDrawer${activeLang[0] + activeLang[1]?.toLowerCase()}`];
    messages = [activeMessage, activeMessage, activeMessage, activeMessage];
  }

  const visibilityHandler = React.useCallback(
    function () {
      Cookies.set('notice_drawer', true);

      return setIsVisible(!isVisible);
    },
    [isVisible],
  );

  React.useEffect(() => {
    const cookies = Cookies.get();

    if (!cookies.notice_drawer && !isVisible) {
      setIsVisible(!isVisible);
    }
  }, []);

  return (
    <div className={contClasses} onClick={() => visibilityHandler()}>
      <Link
        to={activeLang === 'EN' ? '/en/contacts' : '/contatti'}
        className="font-sans tracking-sans uppercase text-sm whitespace-nowrap animate-infinite-banner"
      >
        {messages && messages.map((msg, i) => <React.Fragment key={i}>{parse(msg)} </React.Fragment>)}
      </Link>
    </div>
  );
}

const query = graphql`
  query {
    wp {
      siteSettings {
        ACFSiteSettings {
          noticeDrawer {
            noticeDrawerIt
            noticeDrawerEn
          }
        }
      }
    }
  }
`;
