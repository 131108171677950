import './src/styles/global.css';

import Cookies from 'js-cookie';
import { GeneralContextProvider } from '@components/hoc/useGeneralContext';
import React from 'react';
import smoothScroll from 'smoothscroll';

// export function onClientEntry() {
//   console.log("We've started!");
//   console.log(Cookies.get());
// }

export const wrapRootElement = ({ element }) => <GeneralContextProvider>{element}</GeneralContextProvider>;

/**
  prevent Gatsby from scrolling page to top
  on exit transition before the animation ends
*/
// const transitionDelay = 400;

// export const onInitialClientRender = () => {
//   window.scrollTo(0, 0);
// };

// export const shouldUpdateScroll = ({ prevRouterProps: { location: prevLocation }, routerProps: { location } }) => {
//   if (location.pathname === '/' || location.pathname === '/en/') {
//     return false;
//   }

//   if (location.action === 'PUSH' && (prevLocation.pathname === '/' || prevLocation.pathname === '/en/')) {
//     const target = document.querySelector('main');
//     // window.setTimeout(() => smoothScroll(target, 450), transitionDelay);
//     window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
//   }

//   return false;
// };
