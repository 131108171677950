import { Link, graphql, useStaticQuery } from 'gatsby';
import React, { Fragment } from 'react';

import useGeneralContext from '@components/hoc/useGeneralContext';

const query = graphql`
  {
    wp {
      languages {
        code
        slug
        name
      }
    }
  }
`;

const LangSwitcher = ({ translations }) => {
  const { activeLang } = useGeneralContext();
  const {
    wp: { languages },
  } = useStaticQuery(query);

  if (!activeLang || !translations) return null;

  return (
    <Fragment>
      {languages.map(lang => {
        if (lang.code == activeLang) return;

        let url = translations[0]?.uri.includes('homepage-en')
          ? translations[0]?.uri.replace('homepage-en/', '')
          : translations[0]?.uri;

        if (!url) {
          url = lang.slug === 'en' ? '/en/' : '/';
        }

        return (
          <Link key={lang.slug} className="hover-link" to={url}>
            {lang.name}
          </Link>
        );
      })}
    </Fragment>
  );
};

export default LangSwitcher;
