import { graphql, navigate, useStaticQuery } from 'gatsby';

import React from 'react';

// Create Context Object
const GeneralContext = React.createContext();
// NB. Not sure where location comes from, but it seems to be working!

function parseUrlList(list) {
  return list.map(({ node }) => {
    const translationVal = node.context.translations.length === 0 ? null : node.context.translations[0].uri;

    return {
      [node.context.language.toLowerCase()]: node.path,
      en: translationVal?.includes('homepage-en') ? '/en/' : translationVal,
    };
  });
}

// Create a provider for components to consume and subscribe to changes
export const GeneralContextProvider = ({ children }) => {
  const [activeNav, setActiveNav] = React.useState(false);
  const [activeLang, setActiveLang] = React.useState(null);
  const {
    allSitePage: { edges: pages },
  } = useStaticQuery(query);
  const urlList = parseUrlList(pages);

  // store list of pages w/translations and return pathname based on that

  function getLanguage() {
    const { pathname } = location;

    if (typeof navigator === `undefined`) {
      return 'en';
    }

    const lang = navigator && navigator.language && navigator.language.split('-')[0];
    const translation = urlList.filter(obj => Object.keys(obj).some(key => obj[key] == pathname))[0];

    if (!lang) return 'en';

    switch (lang) {
      case 'it':
        return {
          lang: 'it',
          url: pathname && translation && translation[lang] ? translation[lang] : '/',
        };
      default:
        return {
          lang: 'en',
          url: pathname && translation && translation[lang] ? translation[lang] : '/en/',
        };
    }
  }

  function setActiveNavHandler(val) {
    if (typeof window !== undefined) {
      const header = document.querySelector('header');
      const headerPos = header.getBoundingClientRect().top;

      if (headerPos > 0) {
        header.scrollIntoView({ behavior: 'smooth' });

        return setActiveNav(val);
      }

      return setActiveNav(val);
    }
  }

  React.useEffect(() => {
    const { lang, url } = getLanguage();

    // console.log({ lang });
    // console.log({ url });

    if (lang === activeLang) return;

    setActiveLang(lang.toUpperCase());

    if (typeof window !== undefined && !window.sessionStorage.firstLoad) {
      if (url) {
        navigate(url, { replace: true });
      }

      window.sessionStorage.firstLoad = true;
    }
  }, []);

  // set active lang on page load

  return (
    <GeneralContext.Provider
      value={{
        activeNav,
        setActiveNav: val => setActiveNavHandler(val),
        activeLang,
        setActiveLang: val => setActiveLang(val),
      }}
    >
      {children}
    </GeneralContext.Provider>
  );
};

GeneralContextProvider.displayName = 'GeneralContextProvider';

const query = graphql`
  query {
    allSitePage(filter: { context: { language: { eq: "IT" } } }) {
      edges {
        node {
          context {
            translations {
              uri
              language {
                code
              }
            }
            language
          }
          path
        }
      }
    }
  }
`;

// export for methods
export default () => React.useContext(GeneralContext);
