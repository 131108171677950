// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-en-404-jsx": () => import("./../../../src/pages/en/404.jsx" /* webpackChunkName: "component---src-pages-en-404-jsx" */),
  "component---src-templates-artist-jsx": () => import("./../../../src/templates/artist.jsx" /* webpackChunkName: "component---src-templates-artist-jsx" */),
  "component---src-templates-artists-jsx": () => import("./../../../src/templates/artists.jsx" /* webpackChunkName: "component---src-templates-artists-jsx" */),
  "component---src-templates-homepage-jsx": () => import("./../../../src/templates/homepage.jsx" /* webpackChunkName: "component---src-templates-homepage-jsx" */),
  "component---src-templates-journal-jsx": () => import("./../../../src/templates/journal.jsx" /* webpackChunkName: "component---src-templates-journal-jsx" */),
  "component---src-templates-location-jsx": () => import("./../../../src/templates/location.jsx" /* webpackChunkName: "component---src-templates-location-jsx" */),
  "component---src-templates-locations-jsx": () => import("./../../../src/templates/locations.jsx" /* webpackChunkName: "component---src-templates-locations-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */)
}

