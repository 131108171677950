import CustomLink from '@components/common/CustomLink';
import Image from '@components/common/Image';
import React from 'react';
import { format } from 'date-fns';
import parse from 'html-react-parser';
import styled from 'styled-components';

function PreviewWImage({ url, className, image, date, copy, isLocations, titleSans }) {
  return (
    <article className={`overflow-hidden ${className}`}>
      <StyledCustomLink url={url}>
        {image && <Image image={image} upTo={isLocations ? 'lg2x' : 'md'} />}
        {date && <p className="text-sm font-sans tracking-sans mb-4">{format(new Date(date), 'dd.MM.yyyy')}</p>}
        {copy && <h3 className={`${titleSans ? 'font-sans tracking-sans text-sm' : ''}`}>{parse(copy)}</h3>}
      </StyledCustomLink>
    </article>
  );
}

PreviewWImage.displayName = 'PreviewWithImage';

const StyledCustomLink = styled(CustomLink)`
  display: block;
  opacity: 1;
  transition: opacity 200ms ease-in-out;

  &:hover {
    opacity: 0.75;
  }
`;

export default PreviewWImage;
