import Grid from '@components/common/Grid';
import LangSwitch from '@components/common/LangSwitch';
import { Link } from 'gatsby';
import NoticeDrawer from '@components/common/NoticeDrawer';
import React from 'react';
import useGeneralContext from '@components/hoc/useGeneralContext';

// We purchase antiques and modern works of art.
// We perform evaluations and appraisals.

// Acquistiamo oggetti d'antiquariato e opere d'arte moderne.
// Eseguiamo valutazioni e perizie.

const Header = ({ translations }) => {
  const { activeNav, activeLang, setActiveNav } = useGeneralContext();
  const homeLink = activeLang === 'EN' ? '/en/' : '/';

  return (
    <header id="header" className="mx-auto border-b border-black sticky top-0 border-opacity-10 z-10">
      <Grid className="p-12 bg-white relative z-20">
        <div className="col-span-6">
          <Link to={homeLink} className="hover-link">
            CopettiAntiquari
          </Link>
        </div>
        <div className="col-span-6 text-right">
          {translations && (
            <>
              <LangSwitch translations={translations} />,{' '}
            </>
          )}
          <button type="button" className="hover-link" onClick={() => setActiveNav(!activeNav)}>
            Menu
          </button>
        </div>
      </Grid>
      <NoticeDrawer />
    </header>
  );
};

export default Header;
