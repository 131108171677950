import Image from '@components/common/Image';
import React from 'react';
import VideoPlayer from '@components/common/VideoPlayer';

const colSpanClass = {
  2: 'col-span-12 md:col-span-6',
  3: 'col-span-12 md:col-span-4',
  4: 'col-span-12 md:col-span-3',
  5: 'col-span-12 md:col-span-2',
};

function MediaGridBlock({ columns, borderBottom, media }) {
  return (
    <section
      className={`col-span-12 grid grid-cols-12 gap-x-12 gap-y-12 items-start${
        borderBottom ? ' border-b border-black border-opacity-10 pb-12' : ''
      }`}
    >
      {media.map((item, i) => (
        <div key={i} className={`${colSpanClass[columns]} flex justify-center items-center`}>
          {item.mediaType === 'image' ? (
            <Image image={item.image} />
          ) : (
            <VideoPlayer
              type={item.source}
              src={item.embedSource || item.linkSource}
              preview={undefined}
              settings={{
                autoplay: true,
                loop: true,
                muted: true,
                hideControls: true,
              }}
            />
          )}
        </div>
      ))}
    </section>
  );
}

MediaGridBlock.displayName = 'MediaGridBlock';

export default MediaGridBlock;
