import React from 'react';
import { colStart } from '@src/helpers';

function PostSubtitle({ align, columns, subtitle }) {
  const mdColStart = colStart(align, columns);

  return (
    <section className={`col-span-12 md:col-span-${columns} md:col-start-${mdColStart}`}>
      <h2 className="text-base sm:text-md">{subtitle}</h2>
    </section>
  );
}

export default PostSubtitle;
