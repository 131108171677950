import React from 'react';
import SanitizedContent from '@components/common/SanitizedContent';
import styled from 'styled-components';

function Blockquote({ copy, source }) {
  // use figure for blockquote
  return (
    <div className="col-span-12">
      <figure className="md:max-w-3/4 md:ml-auto lg:max-w-1/2 md:pl-4">
        <StyledBlockquote className="text-base sm:text-md mb-12">
          <SanitizedContent copy={copy} />
        </StyledBlockquote>
        <figcaption>
          <p>{source}</p>
        </figcaption>
      </figure>
    </div>
  );
}

const StyledBlockquote = styled.blockquote`
  ::before {
    content: '«';
  }

  ::after {
    content: '»';
  }
`;

export default Blockquote;
