import InteractivePlus from '@components/icons/InteractivePlus';
import React from 'react';
import clsx from 'clsx';
import { labels } from '@src/constants';
import { motion } from 'framer-motion';
import { useDimensions } from '@components/hooks/useDimensions';
import useGeneralContext from '@components/hoc/useGeneralContext';

export default function ExhibitionsGrid({ gridTitle, exhibition: exhibitions, exhibitionGridCount, borderBottom }) {
  const { activeLang } = useGeneralContext();
  const [isOpen, setIsOpen] = React.useState(false);
  const insideContainer = React.useRef(null);
  const topBar = React.useRef(null);
  const topBarHeight = useDimensions(topBar);
  const containerHeight = useDimensions(insideContainer);

  const contClasses = clsx([
    'col-span-12 pt-12 border-t border-black border-opacity-10 overflow-hidden',
    exhibitionGridCount <= 1 ? 'mt-24 md:mt-48' : '-mt-12',
    borderBottom && 'border-b',
  ]);

  function openStateHandler() {
    return setIsOpen(!isOpen);
  }

  return (
    <motion.section
      initial={false}
      animate={isOpen ? 'open' : 'close'}
      variants={{
        close: {
          height: 48,
          transition: {
            ease: 'easeOut',
          },
        },
        open: {
          height: containerHeight + 48,
          transition: {
            ease: 'easeOut',
          },
        },
      }}
      className={contClasses}
    >
      <div
        ref={topBar}
        className="grid grid-cols-12 gap-x-12 cursor-pointer items-center"
        onClick={() => openStateHandler()}
      >
        {gridTitle && <h2 className="col-span-11">{gridTitle}</h2>}
        {exhibitions && (
          <div className="col-span-1 flex justify-end">
            <InteractivePlus active={isOpen} />
          </div>
        )}
      </div>
      {exhibitions && (
        <section ref={insideContainer} className="mt-12 pb-12">
          <div className="hidden md:grid grid-cols-12 gap-x-12 font-sans tracking-sans text-sm pb-4">
            <span className="col-span-2">{labels.year[activeLang]}</span>
            <span className="col-span-2">{labels.city[activeLang]}</span>
            <span className="col-span-2">{labels.country[activeLang]}</span>
            <span className="col-span-3">{labels.title[activeLang]}</span>
            <span className="col-span-3">{labels.place[activeLang]}</span>
          </div>
          {exhibitions.map((item, i) => (
            <div key={i} className="md:grid grid-cols-12 gap-x-12 border-t border-black border-opacity-10 pt-4">
              <span className="block md:col-span-2">{item.year}</span>
              <span className="md:col-span-2">{item.city}</span>
              <span className="md:hidden">, </span>
              <span className="md:col-span-2">{item.nation}</span>
              <span className="block md:col-span-3">{item.title}</span>
              <span className="block md:col-span-3">{item.location}</span>
            </div>
          ))}
        </section>
      )}
    </motion.section>
  );
}
