import React from 'react';
import useLayoutEffect from '@react-hook/passive-layout-effect';

export function useDimensions(ref) {
  const [clientHeight, setClientHeight] = React.useState(0);

  function clientHeightHandler() {
    setClientHeight(ref.current.clientHeight);
  }

  useLayoutEffect(() => {
    function resizeHandler() {
      window.requestAnimationFrame(clientHeightHandler);
    }

    clientHeightHandler();

    if (typeof window !== undefined) {
      window.addEventListener('resize', resizeHandler, { passive: true });
    }

    return () => {
      window.removeEventListener('resize', resizeHandler, { passive: true });
    };
  }, [clientHeightHandler]);

  return clientHeight;
}
