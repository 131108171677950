import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

function CustomLink({
  children,
  url,
  target,
  rel = 'noopener',
  title,
  className,
  activeClassName,
  activeStyle,
  partiallyActive,
}) {
  if (target)
    return (
      <a href={url} className={className} target={target} rel={rel} title={title}>
        {children}
      </a>
    );

  return (
    <Link
      to={url}
      className={className}
      activeClassName={activeClassName}
      activeStyle={activeStyle}
      partiallyActive={partiallyActive}
      title={title}
    >
      {children}
    </Link>
  );
}

CustomLink.displayName = 'CustomLink';

CustomLink.defaultProps = {
  rel: 'noopener',
};

export default CustomLink;
