import { getAspectRatio, vimeoEmbedUrlParser } from '@src/helpers';
import { motion, useAnimation } from 'framer-motion';
import styled, { css } from 'styled-components';

import PropTypes from 'prop-types';
import React from 'react';
import ReactPlayer from 'react-player/lazy';
import { findDOMNode } from 'react-dom';
import loadable from '@loadable/component';
import screenfull from 'screenfull';
import { useInView } from 'react-intersection-observer';

const VideoControls = loadable(() => import('@components/common/VideoPlayer/VideoControls'));

function VideoPlayer({ type, src, embed, preview, parentLoadHandler, delay, variants, settings }) {
  const { autoplay, muted, loop, hideControls } = settings;
  const controls = useAnimation();
  const isVimeo = type === 'embed' && src.includes('vimeo.');
  const isYt = type === 'embed' && src.includes('youtube.');
  const isUpload = type !== 'embed';
  const player = React.useRef(null);
  const [playing, setPlaying] = React.useState(autoplay);
  const [mute, setMute] = React.useState(muted);
  const [ended, setEnded] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [duration, setDuration] = React.useState(0);
  const [progress, setProgress] = React.useState(0);
  const [seeking, setSeeking] = React.useState(false);
  const [videoLoaded, setVideoLoaded] = React.useState(0);
  const [ratio, setRatio] = React.useState(['16', '9']);
  const threshold = [0.0];

  const [ref, inView] = useInView({
    threshold,
    triggerOnce: false,
    initialInView: false,
    rootMargin: '300px 0px 0px 0px',
  });

  /**
    From React Player's doc:
    
    — For Vimeo videos, hiding controls must be enabled by the video owner.
  */

  // add play button icon on youtube videos

  const config = {
    vimeo: {
      playerOptions: {
        byline: false,
        portrait: false,
        title: false,
        autopause: false,
        speed: true,
        transparent: true,
      },
    },
    youtube: {
      playerVars: {
        rel: 0,
        iv_load_policy: 3,
        modestbranding: 1,
        playsinline: 1,
        controls: 0,
        fs: 0,
      },
      embedOptions: {
        controls: 0,
        modestbranding: 1,
        playsinline: 1,
        rel: 0,
        iv_load_policy: 3,
        fs: 0,
      },
    },
  };

  React.useEffect(() => {
    if (loaded) {
      setTimeout(() => controls.start('animate'), delay * 100);
    }
  }, [loaded]);

  React.useEffect(() => {
    if (!autoplay) return;

    if (!inView) {
      return setPlaying(false);
    }

    setPlaying(true);
  }, [inView]);

  function loadHandler() {
    return setLoaded(true);
  }

  function durationHandler(e) {
    return setDuration(e);
  }

  function progressHandler({ played }) {
    return setProgress(played);
  }

  function playPauseHandler() {
    setPlaying(!playing);

    if (ended) {
      setEnded(!ended);
    }
  }

  function handleFullScreen() {
    screenfull.request(findDOMNode(player.current));
  }

  function muteUnmuteHandler() {
    setMute(!mute);
  }

  function endedHandler() {
    setEnded(!ended);
    setPlaying(!playing);
  }

  function aspectRatioHandler(el) {
    let width, height;

    if (isUpload) {
      width = el.player?.player.videoWidth;
      height = el.player?.player.videoHeight;
    } else {
      width = el.player?.player?.element.width;
      height = el.player?.player?.element.height;
    }

    const calcRatio = getAspectRatio(width, height);

    if (ratio.toString() !== calcRatio.toString()) {
      setRatio(calcRatio);
    }

    loadHandler();
  }

  return (
    <StyledVideoPlayer
      ref={ref}
      preview={preview}
      initial="initial"
      animate={preview ? {} : controls}
      variants={preview ? {} : variants}
      isVimeo={isVimeo}
      ratio={ratio}
      className="video-container"
    >
      <ReactPlayer
        ref={player}
        url={!isUpload ? src : process.env.GATSBY_API_ENDPOINT + src}
        width={'100%'}
        height={'100%'}
        config={config}
        playing={playing}
        muted={mute}
        loop={loop}
        volume={1}
        playsinline
        onProgress={e => progressHandler(e)}
        onDuration={e => durationHandler(e)}
        onReady={e => {
          if (isVimeo || isUpload) {
            return aspectRatioHandler(e.player);
          }
          loadHandler();
        }}
        onEnded={() => endedHandler()}
      />
      {!hideControls && (
        <VideoControls
          playing={playing}
          ended={ended}
          mute={mute}
          duration={duration}
          progress={progress}
          videoLoaded={videoLoaded}
          playPauseHandler={playPauseHandler}
          muteUnmuteHandler={muteUnmuteHandler}
          handleFullScreen={handleFullScreen}
          showPlayBtn={!playing && isYt && progress > 0}
        />
      )}
    </StyledVideoPlayer>
  );
}

VideoPlayer.propTypes = {
  type: PropTypes.string,
  src: PropTypes.string,
  preview: PropTypes.bool,
  parentLoadHandler: PropTypes.func,
  variants: PropTypes.object,
  delay: PropTypes.number,
  settings: PropTypes.object,
};

VideoPlayer.defaultProps = {
  type: '',
  src: '',
  preview: false,
  parentLoadHandler: () => {},
  variants: {},
  delay: 0,
  settings: {},
};

const StyledVideoPlayer = styled(motion.div)`
  width: 100%;
  max-height: 100vh;
  position: relative;

  ::before {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    padding-top: ${({ ratio }) => `calc(100% / (${ratio[0]} / ${ratio[1]}))`};
  }

  video,
  > div {
    max-width: 100%;
  }

  > div:first-child {
    position: absolute;
    top: 0;
    left: 0;
  }

  iframe {
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
`;

export default VideoPlayer;
