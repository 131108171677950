import React from 'react';
import blocksMap from '@components/blocks/blocksMap';
import { resImgs } from '@src/constants';

export const parseSrcSet = srcSet => {
  const array = srcSet.split(', ');

  array.forEach((item, i) => (array[i] = `${process.env.GATSBY_API_URL}${item}`));

  return array.join(', ');
};

export const parseTranslations = translations => {
  const translationsObject = {};
  if (translations.length <= 0) return undefined;

  translations.forEach(translation => {
    const { uri, language } = translation;

    translationsObject[language.code] = { uri };
  });

  return translationsObject;
};

export function renderBlocks(blocks, pageData = null) {
  if (!blocks) return null;
  let exhibitionGridCount = 0;

  return blocks.map((block, i) => {
    const currentBlock = blocksMap.filter(b => b.type.includes(block.fieldGroupName))[0];
    const Component = currentBlock?.block;
    const isCopyBlock = block.fieldGroupName.includes('_Copy');
    const isExhibitionGrid = block.fieldGroupName.includes('_ExhibitionsGrid');

    if (isExhibitionGrid) {
      exhibitionGridCount = exhibitionGridCount + 1;

      block.exhibitionGridCount = exhibitionGridCount;
    }

    const isBio =
      isCopyBlock && (block.title?.toLowerCase() === 'biography' || block.title?.toLowerCase() === 'biografia');

    if (!Component) return null;

    return <Component key={i} order={i} {...block} featuredImage={isBio && pageData?.featuredImage?.node} />;
  });
}

export function colStart(align, cols) {
  const totalCols = 12;

  if (align === 'left') {
    return 0;
  } else if (align === 'center') {
    return Math.round((totalCols - cols) / 2 + 1);
  } else if (align === 'right') {
    return Math.round(totalCols - cols + 1);
  }
}

export const vimeoEmbedUrlParser = url => {
  if (url.includes('//player.')) return url;

  const filtered = url.split('/').filter(item => item !== '');

  return `${filtered[0]}//player.${filtered[1]}/video/${filtered[2]}`;
};

export const getAspectRatio = (width, height) => {
  // find the greatest common divisor
  function gcdHandler(a, b) {
    return b == 0 ? a : gcdHandler(b, a % b);
  }

  const gcd = gcdHandler(width, height);

  return [width / gcd, height / gcd];
};

function srcSetHandler(list, size) {
  // remove unwanted image sizes
  const sizes = resImgs[size]?.sizes.map(s => list.filter(l => l.name === s)[0]);

  if (!sizes) return null;

  return `${sizes[0].sourceUrl}${sizes[1] ? `, ${sizes[1].sourceUrl} 2x` : ''}`;
}

function srcMediaHandler(size) {
  const media = resImgs[size]?.media;

  if (!media) return null;

  return media;
}

export function imgSourcesHandler(sizes) {
  return sizes.map(size => {
    const srcSet = srcSetHandler(sizes, size.name);
    const media = srcMediaHandler(size.name);

    if (!srcSet || !media) return null;

    return <source key={size.name} srcSet={srcSet} media={media} />;
  });
}
