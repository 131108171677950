import CustomLink from '@components/common/CustomLink';
import Grid from '@components/common/Grid';
import PreviewWImage from '@components/PreviewWImage';
import React from 'react';
import clsx from 'clsx';
import useGeneralContext from '@components/hoc/useGeneralContext';

function ContentList({ title, artists, locations, articles, marginTop, link, borderTop, smallGrid }) {
  const items = artists || locations || articles;
  const isPosts = articles;
  const isLocations = locations;
  const gridClasses = clsx([
    'col-span-12',
    marginTop && 'mt-24 md:mt-48 lg:mt-78',
    borderTop && 'pt-12 border-t border-black border-opacity-10',
    smallGrid && '-mt-12',
  ]);
  const itemClasses = clsx([
    'col-span-12 sm:col-span-6',
    !isLocations && !smallGrid && 'md:col-span-4 lg:col-span-3',
    smallGrid && 'md:col-span-3 lg:col-span-2',
  ]);

  if (isPosts) {
    items.sort((a, b) => new Date(b.date) - new Date(a.date));
  }

  return (
    <Grid className={gridClasses}>
      {title && (
        <div className="col-span-12">
          <h2>{title}</h2>
        </div>
      )}
      {items.map(item => (
        <PreviewWImage
          key={item.id}
          className={itemClasses}
          url={item.uri}
          image={item?.featuredImage?.node}
          copy={item.title}
          date={item?.date}
          isLocations={isLocations}
          titleSans
        />
      ))}
      {link && (
        <p className="col-span-12 text-sm font-sans tracking-sans text-center pt-12">
          <CustomLink url={link.url} className="hover-link inline-block border border-black py-12 px-24">
            {link.title}
          </CustomLink>
        </p>
      )}
    </Grid>
  );
}

ContentList.displayName = 'ContentList';

export default ContentList;
