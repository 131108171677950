import { LazyLoadImage } from 'react-lazy-load-image-component';
import React from 'react';
import clsx from 'clsx';
import { imgSourcesHandler } from '@src/helpers';
import parse from 'html-react-parser';

function Image({ image, widthAuto, layoutFill, upTo }) {
  const [loaded, setLoaded] = React.useState(false);
  const alt = image?.altText || image?.node?.altText;
  const caption = image?.caption || image?.node?.caption;
  const sizes = image?.mediaDetails?.sizes || image?.node?.mediaDetails?.sizes;

  // set a max number of sizes for images: ie - preview images
  if (upTo) {
    const upToIndex = sizes.findIndex(item => item.name === upTo);

    if (upToIndex) {
      sizes.splice(upToIndex + 1);
    }
  }

  const defaultImage = sizes[sizes.length - 1];
  const url = defaultImage?.sourceUrl || image?.mediaItemUrl || image?.node?.mediaItemUrl;
  const width = defaultImage?.width || image?.mediaDetails?.width || image?.node?.mediaDetails?.width;
  const height = defaultImage?.height || image?.mediaDetails?.height || image?.node?.mediaDetails?.height;
  const contAspectRatio = widthAuto ? '0' : (height / width) * 100;
  const outerContClasses = clsx([
    'transition-opacity duration-500 ease-in-out',
    !widthAuto && 'w-full',
    loaded ? 'opacity-100' : ' opacity-0',
    layoutFill && 'h-full',
  ]);
  const innerContClasses = clsx(['w-full relative', layoutFill && 'h-full']);
  const imgClasses = clsx([
    'object-cover max-h-full',
    widthAuto ? 'w-auto' : 'absolute w-full',
    layoutFill && 'min-h-full max-w-none top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2',
    !layoutFill && 'top-0 left-0 h-auto',
  ]);
  /**
    description: when/how to use it? do we need it?
    style: alignments? width limits?
  */

  return (
    <figure className={`inline-block ${outerContClasses}`}>
      <div className={innerContClasses} style={{ paddingBottom: `${contAspectRatio}%` }}>
        <picture>
          {imgSourcesHandler(sizes)}
          <LazyLoadImage
            src={!url.includes('https://api.') ? `${process.env.GATSBY_API_ENDPOINT}${url}` : url}
            width={width}
            height={height}
            threshold={500}
            className={imgClasses}
            afterLoad={() => setLoaded(true)}
            alt={alt}
          />
        </picture>
      </div>
      {caption && <figcaption className="font-sans tracking-sans text-sm mt-12 text-left">{parse(caption)}</figcaption>}
    </figure>
  );
}

Image.displayName = 'Image';

export default Image;
