import Blockquote from '@components/blocks/Blockquote';
import ContentList from '@components/blocks/ContentList';
import CopyBlock from '@components/blocks/CopyBlock';
import ExhibitionsGrid from '@components/blocks/ExhibitionsGrid';
import ImageBlock from '@components/blocks/ImageBlock';
import MediaGridBlock from '@components/blocks/MediaGridBlock';
import PostSubtitle from '@components/blocks/PostSubtitle';
import VideoBlock from '@components/blocks/VideoBlock';

export default [
  {
    type: [
      'Artist_Acfartista_Content_Copy',
      'Location_Acfsede_Contents_Copy',
      'Page_Acfhomepage_Content_Copy',
      'Article_Acfarticolo_Content_Copy',
      'Page_Acfpagine_Contents_Copy',
    ],
    block: CopyBlock,
  },
  {
    type: [
      'Artist_Acfartista_Content_Image',
      'Location_Acfsede_Contents_Image',
      'Article_Acfarticolo_Content_Image',
      'Page_Acfpagine_Contents_Image',
    ],
    block: ImageBlock,
  },
  {
    type: [
      'Artist_Acfartista_Content_MediaGrid',
      'Location_Acfsede_Contents_MediaGrid',
      'Article_Acfarticolo_Content_MediaGrid',
      'Page_Acfpagine_Contents_MediaGrid',
    ],
    block: MediaGridBlock,
  },
  {
    type: [
      'Artist_Acfartista_Content_Video',
      'Location_Acfsede_Contents_Video',
      'Article_Acfarticolo_Content_Video',
      'Page_Acfpagine_Contents_Video',
    ],
    block: VideoBlock,
  },
  {
    type: [
      'Page_Acfhomepage_Content_ArtistsList',
      'Page_Acfhomepage_Content_LocationsList',
      'Page_Acfhomepage_Content_ArticlesList',
    ],
    block: ContentList,
  },
  {
    type: ['Article_Acfarticolo_Content_PostSubtitle'],
    block: PostSubtitle,
  },
  {
    type: ['Article_Acfarticolo_Content_Blockquote'],
    block: Blockquote,
  },
  {
    type: ['Artist_Acfartista_Content_ExhibitionsGrid'],
    block: ExhibitionsGrid,
  },
];
