import { AnimatePresence, motion } from 'framer-motion';

import React from 'react';

const variants = { initial: { opacity: 0, y: 10 }, animate: { opacity: 1, y: 0 }, exit: { opacity: 0, y: 10 } };

function Grid({ animKey, transition, children, className }) {
  const classes = `grid grid-cols-12 gap-x-12 gap-y-12 max-w-full ${className}`;

  if (transition)
    return (
      <AnimatePresence exitBeforeEnter>
        <motion.section
          key={animKey}
          initial="initial"
          animate="animate"
          exit="exit"
          variants={variants}
          transition={{ duration: 0.55 }}
          className={classes}
        >
          {children}
        </motion.section>
      </AnimatePresence>
    );

  return <section className={classes}>{children}</section>;
}

export default Grid;
