import { AnimatePresence, motion } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';

import Column from '@components/OpeningCarousel/Column';
import { Helmet } from 'react-helmet';
import React from 'react';
import smoothScroll from 'smoothscroll';
import useGeneralContext from '@components/hoc/useGeneralContext';
import { useMedia } from 'use-media';

function OpeningCarousel({ isHome }) {
  const {
    wp: {
      siteSettings: {
        ACFSiteSettings: {
          openingCarousel: { tagLine, leftColumn, rightColumn },
        },
      },
    },
  } = useStaticQuery(query);
  const [hidden, setHidden] = React.useState(false);
  const { activeLang } = useGeneralContext();
  const activeTagline = activeLang === 'EN' ? tagLine.tagLineEn : tagLine.tagLineIt;
  const allImages = [...leftColumn, ...rightColumn];
  const isWide = useMedia({ minWidth: '1000px' });

  function hideCarouselHandler() {
    if (typeof window !== undefined) {
      const target = document.querySelector('#header');
      return smoothScroll(target, 600);
    }
  }

  // function scrollToHeader() {
  //   if (typeof window !== undefined) {
  //     const target = document.querySelector('#header');
  //     return smoothScroll(target, 350);
  //   }
  // }

  return (
    // <AnimatePresence exitBeforeEnter>
    <>
      <Helmet>
        {allImages.map((item, i) => {
          const url = item?.mediaItemUrl || item?.node?.mediaItemUrl;

          return <link key={i} rel="preload" as="image" href={url} />;
        })}
      </Helmet>
      {isHome && (
        <motion.section
          key="opening-carousel"
          initial={false}
          animate="show"
          exit="hide"
          variants={{
            hide: {
              opacity: 0,
              transition: {
                duration: 0.35,
                delay: 0.1,
              },
            },
            show: {
              opacity: 1,
            },
          }}
          onClick={hideCarouselHandler}
          className="w-full bg-black relative grid grid-cols-12 cursor-pointer overflow-hidden"
          style={{ height: 'calc(100vh - 49px)' }}
          // onAnimationStart={() => scrollToHeader()}
          role="introduction"
        >
          <h1 className="text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 text-md sm:text-lg text-center w-full">
            {activeTagline}
          </h1>
          {isWide ? (
            <>
              <Column images={leftColumn} isFirst />
              <Column images={rightColumn} />
            </>
          ) : (
            <Column images={allImages} isFirst isFull />
          )}
        </motion.section>
      )}
    </>
    // </AnimatePresence>
  );
}

const query = graphql`
  query {
    wp {
      siteSettings {
        ACFSiteSettings {
          openingCarousel {
            tagLine {
              tagLineEn
              tagLineIt
            }
            leftColumn {
              altText
              caption
              mediaDetails {
                width
                height
                sizes {
                  height
                  name
                  sourceUrl
                  width
                }
              }
              mediaItemUrl
            }
            rightColumn {
              altText
              caption
              mediaDetails {
                width
                height
                sizes {
                  height
                  name
                  sourceUrl
                  width
                }
              }
              mediaItemUrl
            }
          }
        }
      }
    }
  }
`;

export default OpeningCarousel;
