import React from 'react';
import VideoPlayer from '@components/common/VideoPlayer';

// preview,
// fullSize,
// parentLoadHandler,
// delay,
// variants,
// settings,

function VideoBlock({ source, embedSource, linkSource, borderBottom, autoplay, loop, mute, hideControls }) {
  return (
    <section className={`col-span-12${borderBottom ? ' border-b border-black pb-12 border-opacity-10' : ''}`}>
      <VideoPlayer
        type={source}
        src={embedSource || linkSource}
        preview={undefined}
        settings={{
          autoplay,
          loop,
          hideControls,
          muted: hideControls ? true : mute,
        }}
      />
    </section>
  );
}

VideoBlock.displayName = 'VideoBlock';

export default VideoBlock;
