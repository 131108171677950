import { AnimatePresence, motion } from 'framer-motion';

import React from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import parse from 'html-react-parser';
import styled from 'styled-components';
import useGeneralContext from '@components/hoc/useGeneralContext';

const copy = {
  disclaimer: {
    EN: `I consent to my e-mail address being used in accordance to your <a href="/en/privacy-policy/" target="_blank">Privacy Policy</a>`,
    IT: `Consento all'utilizzo del mio indirizzo e-mail in linea con la vostra <a href="/informativa-sulla-privacy/" target="_blank">Privacy Policy</a>`,
  },
  button: {
    EN: 'Subscribe',
    IT: 'Iscriviti',
  },
  success: {
    EN: 'Thank you for subscribing!',
    IT: "Grazie per l'iscrizione!",
  },
  error: {
    EN: 'This e-mail address is already subscribed.',
    IT: 'Questo indirizzo e-mail è già iscritto.',
  },
  invalidEmail: {
    EN: 'Use a valid e-mail address.',
    IT: 'Usa un indirizzo e-mail valido.',
  },
};

function NewsletterOptin() {
  const [email, setEmail] = React.useState('');
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const [consent, setConsent] = React.useState(false);
  const emailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const { activeLang } = useGeneralContext();
  const isIT = activeLang === 'IT';
  const inputPlaceholder = 'E-mail';

  function onChangeHandler(e) {
    if (invalidEmail) {
      setInvalidEmail(false);
    }

    return setEmail(e.target.value);
  }

  function handleConsent(e) {
    if (e.target.checked) {
      return setConsent(true);
    }

    return setConsent(false);
  }

  function isEmailValid() {
    return emailRegEx.test(email);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (!consent) return false;

    if (!isEmailValid()) {
      return setInvalidEmail(true);
    }

    const result = await addToMailchimp(email);

    if (result.result === 'error') {
      return setError(true);
    }

    return setSuccess(true);
  }

  // prettier-ignore
  return (
    <section className="col-span-12 p-12 pb-24 relative">
      <AnimatePresence>
        {(success || error) && (
          <motion.p
            key="message"
            initial="hidden"
            animate="visible"
            variants={{
              hidden: {
                opacity: 0,
                y: '-25%',
              },
              visible: {
                opacity: 1,
                y: '-50%',
                transition: {
                  delay: 0.15,
                },
              },
            }}
            className="text-md lg:text-lg tracking-headline absolute top-1/2 left-12"
          >
            {success ? copy.success[activeLang] : copy.error[activeLang]}
          </motion.p>
        )}
      </AnimatePresence>
      <form
        onSubmit={handleSubmit}
        noValidate
        className={`w-full grid grid-cols-12 items-baseline transition-opacity ${
          success || error ? 'opacity-0' : 'opacity-100'
        }`}
      >
        <label
          htmlFor="EMAIL"
          className="col-span-12 flex flex-col sm:flex-row items-baseline text-md lg:text-lg tracking-headline"
        >
          <span style={{ flex: '0 0 auto' }}>Newsletter:</span>
          <StyledInput
            type="email"
            name="EMAIL"
            id="EMAIL"
            placeholder={inputPlaceholder}
            value={email}
            onChange={e => onChangeHandler(e)}
            className="sm:ml-11 focus:outline-none placeholder-gray-350 max-w-full"
          />
        </label>
        <section className="col-span-12">
          <label htmlFor="privacy" className="w-max font-sans leading-16 cursor-pointer text-xs tracking-sans">
            <input
              type="checkbox"
              name="privacy"
              id="privacy"
              onChange={e => handleConsent(e)}
              className={`mr-8 appearance-none w-8 h-8 border border-black rounded-full focus:outline-none cursor-pointer${
                consent ? ' bg-black' : ''
              }`}
            />
            {!consent && (<>{parse(copy.disclaimer[activeLang])}</>)}
          </label>
          {consent && !invalidEmail && (
            <button type="submit" disabled={email === '' || !consent} className="text-left font-sans text-xs tracking-sans disabled:opacity-70 disabled:cursor-not-allowed">
              {copy.button[activeLang]}
            </button>
          )}
          {invalidEmail && (
            <p className="w-max text-left font-sans text-xs tracking-sans inline-block">
              {copy.invalidEmail[activeLang]}
            </p>
          )}
        </section>
      </form>
    </section>
  );
}

NewsletterOptin.displayName = 'NewsletterOptin';

const StyledInput = styled.input`
  flex: 2 1 100%;

  @media (min-width: 640px) {
    max-width: calc(100% - (164px + 12px));
  }
  @media (min-width: 1024px) {
    max-width: calc(100% - (353px + 12px));
  }
`;

export default NewsletterOptin;
