import { graphql, useStaticQuery } from 'gatsby';

import CustomLink from '@components/common/CustomLink';
import Grid from '@components/common/Grid';
import LangSwitch from '@components/common/LangSwitch';
import NewsletterOptIn from '@components/common/NewsletterOptIn';
import React from 'react';
import parse from 'html-react-parser';
import useGeneralContext from '@components/hoc/useGeneralContext';

function Footer({ translations }) {
  const {
    allWpMenu: { nodes: menus },
    wp: {
      siteSettings: {
        ACFSiteSettings: { footerInfo },
      },
    },
  } = useStaticQuery(query);
  const { activeLang } = useGeneralContext();
  const activeMain = menus.filter(menu => menu.slug === `main-navigation-${activeLang?.toLowerCase()}`)[0];
  const activeFooter = menus.filter(menu => menu.slug === `footer-navigation-${activeLang?.toLowerCase()}`)[0];

  if (!activeMain || !activeFooter) return null;

  return (
    <>
      <NewsletterOptIn />
      <footer className="px-12 pb-12 font-sans text-sm tracking-sans">
        <Grid>
          {footerInfo.map(({ column }, i) => (
            <div key={i} className={`col-span-6 lg:col-span-3 xl:col-span-2${i > 0 ? ' order-last' : ''}`}>
              {parse(activeLang === 'IT' ? column.columnIt : column.columnEn)}
            </div>
          ))}
          <div className="col-span-6 lg:col-span-3 xl:col-span-2">
            <ul>
              {activeMain.menuItems.nodes.map(item => (
                <li key={item.id}>
                  <CustomLink
                    url={item.url}
                    className="hover-link"
                    activeClassName="hover-link--active"
                    partiallyActive={true}
                    title={item.label}
                    target={item.url.includes('http') ? '_blank' : null}
                    rel={item.url.includes('http') ? 'nofollow' : null}
                  >
                    {item.label}
                  </CustomLink>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-span-6 lg:col-span-3 xl:col-span-2">
            <ul>
              {translations && (
                <li className="mb-14">
                  <LangSwitch translations={translations} />
                </li>
              )}
              {activeFooter.menuItems.nodes.map((item, i) => (
                <li key={item.id} className={i === 1 ? 'mb-14' : ''}>
                  <CustomLink
                    url={item.url}
                    className="hover-link"
                    activeClassName="hover-link--active"
                    partiallyActive={true}
                    title={item.label}
                    target={item.url.includes('http') ? '_blank' : null}
                    rel={item.url.includes('http') ? 'nofollow' : null}
                  >
                    {item.label}
                  </CustomLink>
                </li>
              ))}
            </ul>
          </div>
        </Grid>
      </footer>
    </>
  );
}

const query = graphql`
  query {
    allWpMenu {
      nodes {
        slug
        menuItems {
          nodes {
            id
            url
            label
            locations
            title
          }
        }
      }
    }
    wp {
      siteSettings {
        ACFSiteSettings {
          footerInfo {
            column {
              columnEn
              columnIt
            }
          }
        }
      }
    }
  }
`;

export default Footer;
