import styled, { css } from 'styled-components';

import React from 'react';

export default function InteractivePlus({ active, action, theme, className }) {
  return (
    <StyledButton
      type="button"
      aria-label={active ? 'Close' : 'Open'}
      onClick={() => (action ? action() : false)}
      isActive={active}
      color={theme}
      className={`w-24 h-24 inline-block relative cursor-pointer ${className}`}
    ></StyledButton>
  );
}

const StyledButton = styled.button`
  &::before,
  &::after {
    content: '';
    display: block;
    width: 12px;
    height: 1px;
    background-color: ${({ color }) => (color === 'light' ? 'white' : 'black')};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &::before {
    transform: translate(-50%, -50%) rotate(90deg);
    transform-origin: center center;
    transition: transform 150ms ease-out;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      &::before {
        transform: translate(-50%, -50%) rotate(0deg);
      }
    `}
`;
