import { Link, graphql, useStaticQuery } from 'gatsby';

import React from 'react';
import { globalHistory } from '@reach/router';
import { labels } from '@src/constants';
import { motion } from 'framer-motion';
import useGeneralContext from '@components/hoc/useGeneralContext';

// change to custom link

function Navigation() {
  const { allWpMenu } = useStaticQuery(query);
  const { activeNav, activeLang, setActiveNav } = useGeneralContext();
  const menus = allWpMenu.nodes;

  React.useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') setActiveNav(false);
    });
  }, [setActiveNav]);

  if (!activeLang) return null;

  const activeMenu = menus.filter(menu => menu.slug === `main-navigation-${activeLang?.toLowerCase()}`)[0];

  // labels
  return (
    <motion.nav
      initial={false}
      animate={activeNav ? 'show' : 'hide'}
      variants={{
        show: {
          x: 0,
        },
        hide: {
          x: '110%',
        },
      }}
      transition={{ ease: 'easeOut' }}
      onClick={() => setActiveNav(!activeNav)}
      className={`flex fixed top-0 right-0 bg-white py-12 px-12 w-full h-full items-start justify-between shadow-left z-40 sm:w-2/3 md:w-1/2`}
    >
      <ul className="text-md sm:text-lg flex-none">
        {activeMenu.menuItems.nodes.map(item => (
          <li key={item.id}>
            {item.url[0] === '/' ? (
              <Link
                to={item.url}
                className="hover-link"
                activeClassName="hover-link--active"
                partiallyActive={true}
                title={item.label}
              >
                {item.label}
              </Link>
            ) : (
              <a href={item.url} className="hover-link" target="_blank" rel="nofollow" title={item.label}>
                {item.label}
              </a>
            )}
          </li>
        ))}
      </ul>
      <button type="button" className="flex-none hover-link" onClick={() => setActiveNav(!activeNav)}>
        {labels.close[activeLang]}
      </button>
    </motion.nav>
  );
}

const query = graphql`
  query {
    allWpMenu {
      nodes {
        slug
        menuItems {
          nodes {
            id
            url
            label
            locations
            title
          }
        }
      }
    }
  }
`;

export default Navigation;
