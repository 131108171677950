import { Helmet } from 'react-helmet';
import Image from '@components/common/Image';
import React from 'react';

function ImageBlock({ order, image, borderBottom }) {
  if (!image) return null;
  const url = image?.mediaItemUrl || image?.node?.mediaItemUrl;

  return (
    <>
      {order === 0 && url && (
        <Helmet>
          <link rel="preload" as="image" href={url} />
        </Helmet>
      )}
      <section
        className={`col-span-12 text-center${borderBottom ? ' border-b border-black border-opacity-10 pb-12' : ''}`}
      >
        <Image order={order} image={image} />
      </section>
    </>
  );
}

export default ImageBlock;
